import React, { useState, useRef, useEffect, useContext } from "react";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ExcellentExport from "excellentexport";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import ContentTable from "../table/ContentTable";
import { Table } from "../table/Table";
import ContentSolid from "../global/ContentSolid";
import { LoadingTable } from "../lazyLoading/LazyLoading";
import { GetLevelsForScore, GetScores } from "../../api/cyclesForScores";
import * as Sentry from "@sentry/react";

// Import Custom Components
import ModalGradingCriteria from "../class/modals/ModalGradingCriteria";
import { validateUserRoleIds } from "../global/GlobalTools";

// Import Context
import { MainContext } from "../../App";

// Record reporting options
const actionOptions = [
  { value: "list", label: "global:buttons.downloadPDF" },
  { value: "excel", label: "global:buttons.downloadExcel" },
];

const ScoresView = () => {
  const componentRef = useRef(); // Create the reference for printing
  const [t] = useTranslation(["global", "class"]);
  const [filters, setFilters] = useState({
    cycle: null,
    level: null,
    subjects: null,
    grade: null,
    group: null,
    programs: null,
    levelProgram: null,
    period: null,
    class: null,
  });
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [levels, setLevels] = useState([]);
  const [groups, setGroups] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [printExport, setPrintExport] = useState(true);
  const [periodSelected, setPeriodSelected] = useState(false);
  const [filterSubjectsValue, setFilterSubjectsValue] = useState("");
  const [exportData, setExportData] = useState([]);
  const [count, setCount] = useState(0);
  const [selectSchoolLevelsOptions, setSelectSchoolLevelsOptions] = useState(
    []
  );
  const { permissions } = useContext(MainContext);
  // Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: formatFilename(filters),
  };

  const [cyclesForScores, setCyclesForScores] = useState([]);
  const [studentForTable, setStudentForTable] = useState([]);
  // Table columns
  const data = React.useMemo(() => studentForTable, [loading]);
  //Data for table
  const columns = React.useMemo(() => {
    // Defines static columns
    const mainColumns = [
      {
        Header: t("class:table.studentName"),
        accessor: "student_name",
        minWidth: 250,
        width: 250,
        maxWidth: 250,
        hideFilter: true,
        hideSort: true,
      },
      {
        Header: t("class:main.className"),
        accessor: "class_name",
        minWidth: 250,
        width: 250,
        maxWidth: 250,
        hideFilter: true,
        hideSort: true,
      },
    ];
    let periodsColumns = [];
    if (periods.length) {
      periodsColumns = periods.map((period) => {
        return {
          Header: t("class:table.period") + " " + period.label,
          accessor: period.accessor,
          minWidth: 50,
          width: 75,
          maxWidth: 100,
          hideFilter: true,
          hideSort: true,
        };
      });
    }
    // Combine the main columns with the periods columns
    if (periodSelected) {
      const periodSelectedColumn = [
        {
          Header: t("class:table.period") + " " + periodSelected.label,
          accessor: periodSelected.accessor,
          minWidth: 50,
          width: 75,
          maxWidth: 100,
          hideFilter: true,
          hideSort: true,
        },
      ];
      mainColumns.push(...periodSelectedColumn);
    } else if (periodsColumns.length) {
      const averageColumn = [
        {
          Header: t("class:table.average"),
          accessor: "average",
          minWidth: 100,
          width: 125,
          maxWidth: 150,
          hideFilter: true,
          hideSort: true,
        },
      ];
      mainColumns.push(...periodsColumns, ...averageColumn);
    }
    return mainColumns;
  }, [loading]);

  /**
   * Obtains all informations
   */
  const getCyclesFullInformation = () => {
    GetLevelsForScore().then((result) => {
      if (result?.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
      } else {
        let cycles = result.data.data;
        //The information obtained is saved in the state
        setCyclesForScores(cycles);
        //Get the current cycle level
        let currentCycle =
          cycles.find((cycle) => cycle.current_cycle == 1) || cycles[0];
        let newFilters = { ...filters };
        newFilters.cycle = currentCycle;
        newFilters.level = currentCycle?.school_levels?.[0] || false;
        newFilters.subjects =
          currentCycle?.school_levels?.[0]?.subjects?.[0] || false;
        setFilters(newFilters);
        const schoolLevels = currentCycle?.school_levels || [];
        // Get shcool levels options for select
        const formatLevels = schoolLevels.reduce((acc, level) => {
          if (level.type === "basic") {
            acc.push({
              label: level.name,
              value: level.organization_school_level_cycle_id,
            });
          }
          return acc;
        }, []);
        setSelectSchoolLevelsOptions(formatLevels);
        setSchoolLevels(schoolLevels);
        setSubjects(currentCycle?.school_levels?.[0]?.subjects || []);
        //Format periods
        const periods = currentCycle?.school_levels?.[0]?.grading_periods || [];
        for (const key in periods) {
          periods[key].label = Number.parseInt(key) + 1;
          periods[key].accessor = "period" + periods[key].id;
        }
        setPeriods(periods);
        //Fill grade or level offers.
        if (schoolLevels[0]?.type == "superior") {
          setPrograms(
            currentCycle?.school_levels?.[0]?.subjects?.[0]?.programs
          );
        } else {
          setGrades(currentCycle?.school_levels?.[0]?.subjects?.[0]?.grades);
        }
        GetScoresFullInfo(newFilters);
      }
    });
  };
  /**
   * Get full information to Scores
   */
  const GetScoresFullInfo = (newFilters = filters) => {
    if (!newFilters.cycle || !newFilters.level || !newFilters.subjects) {
      setStudentForTable([]);
      return;
    }
    setLoading(true);
    let request = {
      school_cycle_id: newFilters.cycle.id,
      school_level_id: newFilters.level.school_level_id,
      subject_id: newFilters.subjects.id,
    };
    if (newFilters.programs?.id) {
      request.program_id = newFilters.programs.id;
    }
    if (newFilters.levelProgram?.id) {
      request.program_level_id = newFilters.levelProgram.id;
    }
    if (newFilters.grade?.id) {
      request.grade_level_id = newFilters.grade.id;
    }
    if (newFilters.group?.id) {
      request.grade_group_id = newFilters.group.id;
    }
    if (newFilters.class?.id) {
      request.class_id = newFilters.class.id;
    }
    GetScores(request)
      .then((result) => {
        if (result?.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
        } else {
          let students = result.data.data;
          if (result.status == 200 && students && students.length > 0) {
            for (const student of students) {
              const scores = student?.scores;
              for (const i of scores) {
                let prop = "period" + i.period_id;
                student[prop] = i.score || "-";
              }
            }
            setStudentForTable(students);
          }else{
            setStudentForTable([]);
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  /**
   * Function to format filters
   * @param {object} filters
   * @returns {string} Formatted filename
   */
  function formatFilename(filters) {
    const periodText = periodSelected
      ? t("class:table.period") + " " + periodSelected.label
      : t("class:filters.allThePeriods");
    return [
      filters?.cycle?.name,
      filters?.level?.name,
      filters?.subjects?.name,
      filters?.grade?.name,
      filters?.programs?.name,
      filters?.levelProgram?.name,
      filters?.group?.name,
      filters?.class?.title,
      periodText,
    ]
      .filter(Boolean)
      .join(" - ");
  }

  /**
   * Changes the value of the selected filters
   * @param {object} value
   * @param {string} filterType
   */
  const selectFilters = (value, filterType) => {
    let newFilters = { ...filters };
    newFilters[filterType] = value;
    switch (filterType) {
      case "cycle":
        {
          const levels = value.school_levels || [];
          const firstLevel = levels[0];
          const subjects = firstLevel?.subjects || [];
          const firstSubject = subjects[0];
          const formatLevels = levels.reduce((acc, level) => {
            if (level.type === "basic") {
              acc.push({
                label: level.name,
                value: level.organization_school_level_cycle_id,
              });
            }
            return acc;
          }, []);
          setSelectSchoolLevelsOptions(formatLevels);
          setSchoolLevels(levels);
          setSubjects(subjects);
          setFilterSubjectsValue("");
          setPrograms(firstSubject?.programs || []);
          setGrades(firstSubject?.grades || []);
          // Clean selector data
          newFilters.level = firstLevel;
          newFilters.subjects = firstSubject;
          newFilters.grade = null;
          newFilters.group = null;
          newFilters.programs = null;
          newFilters.levelProgram = null;
          newFilters.class = null;
          // Format periods
          const periods = firstLevel?.grading_periods || [];
          for (const key in periods) {
            periods[key].label = Number.parseInt(key) + 1;
            periods[key].accessor = "period" + periods[key].id;
          }
          setPeriods(periods);
          setPeriodSelected(false);
        }
        break;
      case "level":
        {
          const subjects = value.subjects || [];
          const firstSubject = subjects[0];
          setSubjects(subjects);
          setFilterSubjectsValue("");
          setGrades(firstSubject.grades || []);
          setPrograms(firstSubject.programs || []);
          // Clean selector data
          newFilters.subjects = firstSubject;
          newFilters.grade = null;
          newFilters.group = null;
          newFilters.programs = null;
          newFilters.levelProgram = null;
          newFilters.class = null;
          // Format periods
          const periods = value.grading_periods || [];
          for (const key in periods) {
            periods[key].label = Number.parseInt(key) + 1;
            periods[key].accessor = "period" + periods[key].id;
          }
          setPeriods(periods);
          setPeriodSelected(false);
        }
        break;
      case "subjects":
        setPrograms(value?.programs || []);
        setGrades(value?.grades || []);
        newFilters.grade = null;
        newFilters.group = null;
        newFilters.programs = null;
        newFilters.levelProgram = null;
        newFilters.class = null;
        setPeriodSelected(false);
        break;
      case "grade":
        setGroups(value?.groups || []);
        newFilters.group = null;
        newFilters.programs = null;
        newFilters.levelProgram = null;
        newFilters.class = null;
        setPeriodSelected(false);
        break;
      case "group":
        setClasses(value?.classes || []);
        newFilters.programs = null;
        newFilters.levelProgram = null;
        newFilters.class = null;
        setPeriodSelected(false);
        break;
      case "programs":
        setLevels(value?.program_levels || []);
        newFilters.levelProgram = null;
        newFilters.class = null;
        setPeriodSelected(false);
        break;
      case "levelProgram":
        setClasses(value?.classes || []);
        newFilters.class = null;
        setPeriodSelected(false);
        break;
      case "class":
        newFilters.class;
        break;
    }
    setFilters(newFilters);
    GetScoresFullInfo(newFilters);
  };
  //Export to Excel
  const downloadFile = (exportOptions, exportData) => {
    ExcellentExport.convert(exportOptions, exportData);
  };

  /*
   * Initial Loading
   */
  useEffect(() => {
    getCyclesFullInformation();
  }, []);

  /**
   * Description: Function to set headers according to filters
   * @returns header elements
   */
  const getDataFilters = () => {
    return (
      <Container className="fs-6" fluid>
        <Row className="mt-3">
          <Col>
            <b>{t("class:headerScoresPDF.schoolCycle")}</b>
            {filters.cycle?.name}
          </Col>
          <Col>
            <b>{t("class:headerScoresPDF.subject")}</b>
            {filters.subjects?.name}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <b>{t("class:headerScoresPDF.schooling")}</b>
            {filters.level?.name}
          </Col>
          <Col>
            <b>{t("class:headerScoresPDF.period")}</b>
            {periodSelected?.label || t("class:filters.textAll")}
          </Col>
        </Row>
        {filters.level?.type === "superior" ? (
          <>
            <Row className="mt-2">
              <Col>
                <b>{t("class:headerScoresPDF.academicOffer")}</b>
                {filters.programs?.name}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <b>{t("class:headerScoresPDF.levelOffer")}</b>
                {filters.levelProgram?.name || t("class:filters.textAll")}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="mt-2">
              <Col>
                <b>{t("class:headerScoresPDF.grade")}</b>
                {filters.grade?.name || t("class:filters.textAll")}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <b>{t("class:headerScoresPDF.group")}</b>
                {filters.group?.name || t("class:filters.textAll")}
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  };

  return (
    <Container fluid className="mt-3">
      <Row className="mb-3">
        <Col md={12} className="mb-4 ms-2">
          <Dropdown style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="downloadOptionCycle"
              variant="outline-secondary"
            >
              {filters?.cycle?.name
                ? filters?.cycle.name
                : t("class:filters:selectCycle")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {cyclesForScores && cyclesForScores.length > 0 ? (
                cyclesForScores.map((cycle) => (
                  <Dropdown.Item
                    key={cycle.value}
                    onClick={() => {
                      selectFilters(cycle, "cycle");
                    }}
                  >
                    {cycle.name}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item disabled>
                  {t("global:select.noMatches")}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col md={8}>
          <Dropdown className="ms-2 d-inline-block">
            <Dropdown.Toggle
              id="schoolLevel"
              variant="outline-secondary"
              disabled={!filters?.cycle?.name}
            >
              {filters?.level?.name
                ? filters?.level?.name
                : t("class:filters.selectLevel")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {schoolLevels &&
                schoolLevels.map((level) => {
                  return (
                    <Dropdown.Item
                      key={level.value}
                      onClick={() => {
                        selectFilters(level, "level");
                      }}
                    >
                      {level.name}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            className="ms-2 d-inline-block text-break"
            style={{ maxWidth: "200px" }}
          >
            <Dropdown.Toggle
              id="subject"
              variant="outline-secondary"
              disabled={!filters?.level?.name}
              style={{ maxWidth: "200px" }}
              className="text-truncate"
            >
              {filters?.subjects?.name
                ? filters?.subjects?.name
                : t("class:table.subject")}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="text-truncate"
              style={{ maxWidth: "200px" }}
            >
              <input
                className="mx-1"
                style={{ width: "95%" }}
                autoFocus
                onChange={(e) => setFilterSubjectsValue(e.target.value)}
                value={filterSubjectsValue}
              />

              {subjects &&
                subjects
                  .filter(
                    (subject) =>
                      !filterSubjectsValue ||
                      subject.name
                        .toLowerCase()
                        .includes(filterSubjectsValue.toLowerCase())
                  )
                  .map((subject) => {
                    return (
                      <Dropdown.Item
                        key={subject.value}
                        onClick={() => {
                          selectFilters(subject, "subjects");
                          setFilterSubjectsValue("");
                        }}
                        className="text-truncate"
                      >
                        {subject.name}
                      </Dropdown.Item>
                    );
                  })}
            </Dropdown.Menu>
          </Dropdown>
          {filters?.level && filters?.level?.type === "superior" ? (
            <>
              {/* Academic Offer */}
              <Dropdown className="ms-2" style={{ display: "inline-block" }}>
                <Dropdown.Toggle
                  id="academicOffer"
                  variant="outline-secondary"
                  disabled={!filters?.subjects?.name}
                >
                  {filters?.programs
                    ? filters?.programs?.name
                    : t("class:filters.selectAcademicOffer")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {programs &&
                    programs.map((programs) => {
                      return (
                        <Dropdown.Item
                          key={programs.value}
                          onClick={() => {
                            selectFilters(programs, "programs");
                          }}
                        >
                          {programs.name}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="ms-2" style={{ display: "inline-block" }}>
                <Dropdown.Toggle
                  id="levelProgram"
                  variant="outline-secondary"
                  disabled={!filters?.programs?.name}
                >
                  {filters?.levelProgram?.name || t("class:modalImport.level")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    key={"allProgramLevels"}
                    onClick={() => {
                      selectFilters(null, "levelProgram");
                    }}
                  >
                    {t("class:filters.textAll")}
                  </Dropdown.Item>
                  {levels &&
                    levels.map((levels) => {
                      return (
                        <Dropdown.Item
                          key={levels.value}
                          onClick={() => {
                            selectFilters(levels, "levelProgram");
                          }}
                        >
                          {levels.name}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              <Dropdown className="ms-2" style={{ display: "inline-block" }}>
                <Dropdown.Toggle
                  id="grade"
                  variant="outline-secondary"
                  disabled={!filters?.subjects?.name}
                >
                  {filters?.grade?.name || t("class:table.grade")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    key="allGrades"
                    onClick={() => {
                      selectFilters(null, "grade");
                    }}
                  >
                    {t("class:filters.textAll")}
                  </Dropdown.Item>
                  {grades &&
                    grades.map((grade) => {
                      return (
                        <Dropdown.Item
                          key={grade.name}
                          onClick={() => {
                            selectFilters(grade, "grade");
                          }}
                        >
                          {grade.name}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="ms-2" style={{ display: "inline-block" }}>
                <Dropdown.Toggle
                  id="group"
                  variant="outline-secondary"
                  disabled={!filters?.grade?.name}
                >
                  {filters?.group?.name || t("class:table.group")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    key="allGroups"
                    onClick={() => {
                      selectFilters(null, "group");
                    }}
                  >
                    {t("class:filters.textAll")}
                  </Dropdown.Item>
                  {groups &&
                    groups.map((group) => {
                      return (
                        <Dropdown.Item
                          key={group.value}
                          onClick={() => {
                            selectFilters(group, "group");
                          }}
                        >
                          {group.name}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
          <Dropdown className="ms-2" style={{ display: "inline-block" }}>
            <Dropdown.Toggle
              id="periods"
              variant="outline-secondary"
              disabled={!filters?.level?.name && !filters?.levelProgram?.name}
            >
              {periodSelected != false
                ? t("class:table.period") + " " + periodSelected?.label
                : t("class:filters.allThePeriods")}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "auto" }}>
              <Dropdown.Item
                key="allPeriods"
                onClick={() => {
                  setPeriodSelected(false);
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                  }, 300);
                }}
              >
                {t("class:filters.allThePeriods")}
              </Dropdown.Item>
              {periods &&
                periods.map((period) => {
                  return (
                    <Dropdown.Item
                      key={period.value}
                      onClick={() => {
                        setPeriodSelected(period);
                        setLoading(true);
                        setTimeout(() => {
                          setLoading(false);
                        }, 300);
                      }}
                    >
                      {t("class:table.period") + " " + period.label}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
          {(filters?.levelProgram?.name || filters?.group?.name) && (
            <Dropdown className="ms-2" style={{ display: "inline-block" }}>
              <Dropdown.Toggle
                id="classes"
                variant="outline-secondary"
                disabled={!filters?.group?.name && !filters?.levelProgram?.name}
              >
                {filters?.class?.title || t("class:main.class")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  key="allClasses"
                  onClick={() => {
                    selectFilters(null, "classes");
                  }}
                >
                  {t("class:filters.allClasses")}
                </Dropdown.Item>
                {classes &&
                  classes.map((classes) => {
                    return (
                      <Dropdown.Item
                        key={classes.value}
                        onClick={() => {
                          {
                            selectFilters(classes, "class");
                          }
                        }}
                      >
                        {classes.title}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <p
            className="ms-3 d-inline"
            style={{ color: "#c8cbcc", fontWeight: "bold", fontSize: "small" }}
          >
            {t("global:totalRecords", { count: count })}
          </p>
        </Col>
        <Col md={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              float: "right",
            }}
          >
            {/* Modal for grading criteria */}
            {permissions?.granding_criteria?.access &&
              permissions?.granding_criteria?.create &&
              permissions?.granding_criteria?.edit &&
              permissions?.granding_criteria?.delete &&
              validateUserRoleIds([1, 2]) && (
                <ModalGradingCriteria
                  openButtonStyle={{ marginRight: "10px" }}
                  schoolLevels={selectSchoolLevelsOptions}
                  isLoading={loading}
                />
              )}
            <Dropdown className="float-end" style={{ display: "inline-block" }}>
              <Dropdown.Toggle
                id="dropdownReportCards"
                variant="outline-secondary"
                disabled={!studentForTable.length}
              >
                <i className="bi bi-download"></i>
                {t("global:buttons.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu disabled={printExport}>
                {actionOptions.map((action) => {
                  return action.value == "list" ? (
                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                      )}
                      content={() => componentRef.current}
                      documentTitle={formatFilename(filters)}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                      )}
                      content={() => downloadFile(exportOptions, exportData)}
                    />
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      {/* TABLE */}
      {loading ? (
        <ContentSolid className="mt-3">
          <LoadingTable />
        </ContentSolid>
      ) : (
        <ContentTable startColumFilter={0} lastColumFilter={4}>
          <Table
            ref={componentRef}
            columns={columns}
            data={data}
            setExportData={setExportData}
            setPrintExport={setPrintExport}
            limitRowsShown={50}
            setRowsDisplayed={setCount}
            headerInformation={getDataFilters}
          ></Table>
        </ContentTable>
      )}
    </Container>
  );
};

ScoresView.propTypes = {
  value: PropTypes.object.isRequired,
  cell: PropTypes.object,
};
export default ScoresView;
