import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Form,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { SaveEvaluationCriteria } from "../../../api/class";
import TextLoading from "../../global/TextLoading";
import ContentButton from "../../global/ContentButton";
import ModalSuccess from "../../global/modals/ModalSuccess";

const customStyles = {
  container: {
    background: "#f6f6f6",
    borderRadius: "8px",
  },
  plusIcon: {
    cursor: "pointer",
    color: "#ea2c54",
    fontSize: "15px",
    marginLeft: "7px",
  },
  listIcon: {
    cursor: "n-resize",
    fontSize: "15px",
  },
  dashIcon: {
    cursor: "pointer",
    fontSize: "15px",
  },
  dropZone: {
    border: "1px solid #e7e7e7",
    padding: " 5px",
    borderRadius: "8px",
    // minHeight: "200px",
  },
};

const AddCriterian = (props) => {
  const [t] = useTranslation(["global", "captureGrades"]);
  const [showAddCriteriaModal, setShowAddCriteriaModal] = useState(
    props.showAddCriterian
  );
  const criteria =
    Object.keys(props.criterianForEdit).length > 0
      ? props.criterianForEdit
      : props.criteriaSelected;
  const [criteriaData, setCriteriaData] = useState(criteria);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [indexTemp, setIndexTemp] = useState(false);

  /**
   * Description: Check grading criterias
   */
  useEffect(() => {
    // Calculate total percentage
    const totalPercentage = criteriaData.second_criteria.reduce(
      (acc, { percentage }) => acc + (Number(percentage) || 0),
      0
    );
    // Save the sum of the percentages
    setTotalPercentage(totalPercentage);
  }, [criteriaData]);

  /**
   * Description: Function to change values in second criterias
   * @param {object} e
   * @param {number} position
   */
  const handleChange = (e, position) => {
    const newSecondCriterias = [...criteriaData.second_criteria];
    const field = e.target.name;
    const value = e.target.value;
    // Validate percentage values entered
    if (field === "percentage") {
      newSecondCriterias[position].invalidPecentage = value == 0;
      const limit = 100;
      const isValid = value.match(/^[0-9]*$/) !== null && value <= limit;
      if (!isValid) return;
    }
    if (field === "name") {
      newSecondCriterias[position].invalidName = value === "";
    }
    newSecondCriterias[position][field] = value;
    // update the state
    setCriteriaData((prev) => ({
      ...prev,
      second_criteria: newSecondCriterias,
    }));
  };

  /**
   * Description: Function to add a new second criteria
   */
  const addSecondCriteria = () => {
    const isEvaluable = criteriaData.evaluable === "1";
    const isEmptyList = totalPercentage === 0;
    const newSecondCriterias = [
      ...criteriaData.second_criteria,
      {
        name: "",
        percentage: isEvaluable && isEmptyList ? 100 : 0,
      },
    ];
    // update the state
    setCriteriaData((prev) => ({
      ...prev,
      second_criteria: newSecondCriterias,
    }));
  };

  /**
   * Purpose: Verify if grading criteria has id
   * @param {number} index
   */
  const checkRemoveCriteria = (index) => {
    const criteriaToRemove = criteriaData.second_criteria[index];
    if (criteriaToRemove?.grading_criterion_id) {
      setIndexTemp(index);
      setShowAddCriteriaModal(false);
      setShowDeleteModal(true);
    } else {
      removeLocalSecondCriteria(index);
    }
  };

  /**
   * Description: Function to remove a second criteria
   * @param {number} index
   */
  const removeLocalSecondCriteria = (index) => {
    const newSecondCriterias = [...criteriaData.second_criteria];
    newSecondCriterias.splice(index, 1);
    // update the state
    setCriteriaData((prev) => ({
      ...prev,
      second_criteria: newSecondCriterias,
    }));
  };

  /**
   * Description: Remove an existing grading criteria
   */
  const removeSecondCriteria = () => {
    // Check if it is evaluable
    const isEvaluable = criteriaData.evaluable === "1";

    // Create a copy of the current criteria and remove the selected criterion
    const newSecondCriterias = [...criteriaData.second_criteria];
    newSecondCriterias.splice(indexTemp, 1);

    // If the removed criterion is evaluable, recalculate the percentages
    if (isEvaluable) {
      // Calculate the new percentage
      const totalCriteriasEvaluables = newSecondCriterias?.length || 1;
      const equalPercentage = 100 / totalCriteriasEvaluables;

      // Assign the new percentage to the evaluable criteria
      const criteriasEqualPercentage = newSecondCriterias.map((criteria) => {
        return {
          ...criteria,
          percentage: equalPercentage,
        };
      });

      // Save the criteria with recalculated percentages
      saveCriterias({
        ...criteriaData,
        second_criteria: criteriasEqualPercentage,
      });
    } else {
      // Save the criteria without changes to the percentages
      saveCriterias({ ...criteriaData, second_criteria: newSecondCriterias });
    }
    // Close the confirmation modal for the deletion
    setShowDeleteModal(false);
  };

  /**
   * Description: Function to check if all grading criterias are valid
   * @param {array} [secondCriterias=[]]
   * @return {boolean}
   */
  const validateForm = (secondCriterias = []) => {
    let isValid = true;
    const newCriterias = [];
    const isEvaluable = criteriaData.evaluable === "1";
    // Validate criterias
    for (const criteria of secondCriterias) {
      const isValidPercentage = Number(criteria?.percentage || 0) > 0;
      const isCorrectName = criteria?.name?.length;
      const isCorrectPercentage =
        (isValidPercentage && isEvaluable) ||
        (!isValidPercentage && !isEvaluable);
      // Set criterias validated
      criteria.invalidName = !isCorrectName;
      criteria.invalidPecentage = !isCorrectPercentage;
      newCriterias.push(criteria);
    }
    // Update the values to show or hide validation in the fields
    setCriteriaData((prev) => ({
      ...prev,
      second_criteria: newCriterias,
    }));
    // Validate if all second criterias are valid for continue to send api
    isValid = secondCriterias.every(
      (criteria) => !criteria.invalidName && !criteria.invalidPecentage
    );
    return isValid;
  };

  /**
   * Description: Function to create secondary grading criteria
   */
  const saveCriterias = (criterias = criteriaData) => {
    if (!validateForm(criterias.second_criteria)) {
      return;
    }
    const secondCriterias = criterias.second_criteria?.map(
      (criteria, index) => {
        return {
          name: criteria.name,
          percentage: Number(criteria.percentage),
          criterion_level: 2,
          order: index + 1,
          grading_criterion_id: criteria?.grading_criterion_id,
        };
      }
    );
    const payload = {
      class_id: props.classId,
      main_criterion_class_id: criterias.grading_criterion_class_id,
      grading_criteria: secondCriterias,
    };
    setIsSaving(true);
    SaveEvaluationCriteria(payload)
      .then((response) => {
        if (response?.data) {
          // Validate if the class has 2nd level criteria
          const hasSecondCriteria = response.data.some(
            (criteria) => criteria.second_criteria?.length
          );
          // Add id de primer criterio dentro de segundo criterio
          if (hasSecondCriteria) {
            for (const element of response.data) {
              if (element.second_criteria?.length) {
                for (const secondCriteria of element.second_criteria) {
                  secondCriteria.first_criteria_id =
                    element.grading_criterion_class_id;
                }
              }
            }
          }
          // Update local criterias
          props.updateCriterias(response.data);
          // Check if the selected criteria is within the modified ones
          let newCriteriaSelected = secondCriterias.find(
            (criteria) =>
              criteria.grading_criterion_id ==
              props.criteriaSelected?.grading_criterion_id
          );
          // Update the criteria if it is the one selected
          if (newCriteriaSelected) {
            props.updateCriteriaSelected({
              ...props.criteriaSelected,
              name: newCriteriaSelected.name,
              percentage: newCriteriaSelected.percentage,
            });
          } else {
            // Find the selected criterion
            // Identify whether the selected criterion is first level or second level.
            const isSecondCriteria = !!props.criteriaSelected.first_criteria_id;
            if (isSecondCriteria) {
              // Search for the first level criterion
              const firstCriteria = response.data.find(
                (criteria) =>
                  criteria.grading_criterion_class_id ==
                  props.criteriaSelected?.first_criteria_id
              );
              // Search for second level criteria
              const hasSecondCriteria = firstCriteria.second_criteria?.length;
              if (hasSecondCriteria) {
                // Select the first criterion of the second level
                newCriteriaSelected = firstCriteria.second_criteria[0];
              } else {
                newCriteriaSelected = firstCriteria;
              }
              props.updateCriteriaSelected(newCriteriaSelected);
            } else {
              // to know if the selected first level criterion now has second level criteria
              const criteriaSelectedUpdated = response.data.find(
                (criteria) =>
                  criteria.grading_criterion_id ==
                  props.criteriaSelected?.grading_criterion_id
              );
              if (
                criteriaSelectedUpdated &&
                criteriaSelectedUpdated.second_criteria?.length
              ) {
                props.updateCriteriaSelected(
                  criteriaSelectedUpdated.second_criteria[0]
                );
              }
            }
          }
          // Close modal
          props.onHide(false);
        } else {
          setError(response?.description);
        }
      })
      .finally(() => {
        setIsSaving(false);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  return (
    <>
      {props.showAddCriterian && (
        <Modal show={showAddCriteriaModal} size="lg">
          <Modal.Header>
            <Modal.Title className="modal-title">
              {t("captureGrades:modalAddCriterian.title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container style={customStyles.container} className="py-3">
              {/* 1st Level Title*/}
              <Row>
                <Col md={6}>
                  <Form.Label>
                    {t("captureGrades:modalAddCriterian.1stLevelOfCriteria")}
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Check className="ms-1 d-inline form-switch float-end">
                    {t(
                      "captureGrades:modalAddCriterian.considerRatingCalculation"
                    )}
                    <Form.Check.Input
                      id="capture"
                      type="checkbox"
                      style={{ marginLeft: "5px" }}
                      checked={criteriaData.evaluable === "1"}
                      disabled
                    />
                  </Form.Check>
                </Col>
              </Row>
              {/* 1st Level Criterian */}
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {t("captureGrades:modalAddCriterian.evaluationCriteria")}
                    </Form.Label>
                    <Form.Control
                      name="nameFirstCriterian"
                      placeholder=""
                      value={criteriaData.name}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {t("captureGrades:modalAddCriterian.evaluationRate")}
                    </Form.Label>
                    <Form.Control
                      name="nameFirstCriterian"
                      placeholder=""
                      value={criteriaData.percentage}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* 2nd Level Title*/}
              <Row className="mt-3">
                <Col md={12}>
                  <Form.Label>
                    {t("captureGrades:modalAddCriterian.2ndLevelOfCriteria")}
                  </Form.Label>
                  <i
                    value="add"
                    className="bi bi-plus-circle-fill"
                    style={customStyles.plusIcon}
                    onClick={addSecondCriteria}
                  ></i>
                </Col>
              </Row>
              {/* 2nd Level Criterian */}
              {criteriaData.second_criteria && (
                <ReactSortable
                  list={criteriaData.second_criteria}
                  setList={(newState) =>
                    setCriteriaData({
                      ...criteriaData,
                      second_criteria: newState,
                    })
                  }
                  animation={200}
                  delayOnTouchStart={true}
                  removeOnSpill={false}
                  handle=".bi-list"
                >
                  {criteriaData.second_criteria.map((secondCriteria, index) => (
                    <Row className="mb-4" key={index}>
                      <Col md={6}>
                        <Row>
                          {/* icon to sort */}
                          <Col md={1} style={{ paddingTop: "35px" }}>
                            <i
                              style={customStyles.listIcon}
                              className="bi bi-list"
                            ></i>
                          </Col>
                          <Col md={11}>
                            <Form.Group>
                              <Form.Label>
                                {t(
                                  "captureGrades:modalAddCriterian.evaluationCriteria"
                                )}
                              </Form.Label>
                              <Form.Control
                                name="name"
                                className={
                                  secondCriteria.invalidName
                                    ? "errorValidation"
                                    : "well"
                                }
                                placeholder=""
                                value={secondCriteria.name}
                                onChange={(e) => handleChange(e, index)}
                                maxLength={30}
                              />
                              {secondCriteria.invalidName && (
                                <Form.Text className="text-muted error">
                                  {t(
                                    "captureGrades:modalAddCriterian.nameRequired"
                                  )}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={11}>
                            <Form.Group>
                              <Form.Label>
                                {t(
                                  "captureGrades:modalAddCriterian.evaluationRate"
                                )}
                              </Form.Label>
                              <Form.Control
                                name="percentage"
                                className={
                                  secondCriteria.invalidPecentage
                                    ? "errorValidation"
                                    : "well"
                                }
                                placeholder=""
                                value={secondCriteria.percentage}
                                onChange={(e) => handleChange(e, index)}
                                disabled={criteriaData.evaluable !== "1"}
                              />
                            </Form.Group>
                          </Col>
                          {/* icon to remove */}
                          <Col md={1} style={{ paddingTop: "35px" }}>
                            <i
                              style={customStyles.dashIcon}
                              className="bi bi-dash-circle-fill float-end"
                              onClick={() => checkRemoveCriteria(index)}
                            />
                          </Col>
                        </Row>
                        {secondCriteria.invalidPecentage && (
                          <Form.Text className="text-muted error">
                            {t(
                              "captureGrades:modalAddCriterian.percentageRequired"
                            )}
                          </Form.Text>
                        )}
                      </Col>
                    </Row>
                  ))}
                </ReactSortable>
              )}
            </Container>
            {/**Grading criteria total percentage */}
            {criteriaData.evaluable === "1" &&
              criteriaData.second_criteria?.length > 0 && (
                <p
                  className="mt-4 mb-3"
                  style={{
                    color: totalPercentage == 100 ? "#10B981" : "#E61414",
                    fontWeight: "bold",
                  }}
                >
                  {t("captureGrades:modalAddCriterian.sumTotalPercentage", {
                    percentage: totalPercentage,
                  })}
                </p>
              )}
            {/**Errors */}
            {error && (
              <Row className="mt-3">
                <Col md={12}>
                  <Alert variant="warning">{error}</Alert>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ContentButton className="content-button">
              <Button
                variant="outline-secondary"
                disabled={isSaving}
                onClick={() => props.onHide(false)}
              >
                {t("global:buttons.cancel")}
              </Button>
              <Button
                disabled={
                  isSaving ||
                  (criteriaData.evaluable === "1" && totalPercentage !== 100)
                }
                onClick={() => saveCriterias()}
              >
                {isSaving ? (
                  <TextLoading
                    text={t("global:buttons.saving")}
                    variant="light"
                  />
                ) : (
                  t("global:buttons.save")
                )}
              </Button>
            </ContentButton>
          </Modal.Footer>
        </Modal>
      )}
      {/** Delete Grading Criteria Modal */}
      {showDeleteModal && (
        <ModalSuccess
          showModalSuccess={showDeleteModal}
          title={t("captureGrades:modalAddCriterian.deleteTitle")}
          message={t("captureGrades:modalAddCriterian.deleteDescription")}
          fnCancelButton={() => {
            setShowDeleteModal(false);
            setShowAddCriteriaModal(true);
          }}
          loading={isSaving}
          txtBtnAccept={t("global:buttons.eliminate")}
          fnAcceptButton={removeSecondCriteria}
        />
      )}
    </>
  );
};

AddCriterian.propTypes = {
  showAddCriterian: PropTypes.bool,
  onHide: PropTypes.func,
  criterianForEdit: PropTypes.object,
  classId: PropTypes.string,
  updateCriterias: PropTypes.func,
  criteriaSelected: PropTypes.object,
  updateCriteriaSelected: PropTypes.func,
};

export default AddCriterian;
