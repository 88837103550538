import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Col, Button, Row, Alert } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import AcademicModalView from "../../pages/crm/AcademicModalView";
/** Services */
import {
  getSchoolLevels,
  createleaflet,
  CreateAcquisitionMethods,
} from "../../api/Crm";

/** Elements */
import FieldInput from "../../components/global/form/FieldInput";
import FormSelect from "../../components/global/form/FormSelect";
import TextLoading from "../../components/global/TextLoading";
import ContentButton from "../../components/global/ContentButton";
import { goToHref } from "../../components/global/GlobalTools";
import HelpBlock from "../../components/global/form/HelpBlock";

export default function ModalAddProspect(props) {
  const [t] = useTranslation([
    "crm",
    "global",
    "modalCreateStudent",
    "modalImportStudent",
  ]);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [loadCreateLeaflet, setLoadCreateLeaflet] = useState(false);
  const [error, setError] = useState(false);
  const [methodsAcquisition, setMethodsAcquisition] = useState([]);
  const [collagesAndSchool, setCollagesAndSchool] = useState([]);
  const [showAcademicModalView, setshowAcademicModalView] = useState(false);
  const [higherLevel, sethigherLevel] = useState(false);
  const [adquisitionMethod, setadquisitionMethod] = useState(null);
  const [oferAcademic, setOferAcademic] = useState(null);
  const [gradeSelected, setGradeSelected] = useState(null);
  const [academicOffert, setacademicOffert] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [addingAcquisitionMethod, setAddingAcquisitionMethod] = useState(false);
  const [showAlertPhoneEmail, setShowAlertPhoneEmail] = useState(true);
  const [schoolCycleId, setschoolCycleId] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    homePhone: "",
    email: "",
    schoolLevel: "",
    schoolOrigin: "",
    grade: "",
    acquisitionMethod: "",
    program: "",
    addSource: "",
  });
  /**
   * Validate fields
   */
  const validateSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t("modalCreateStudent:labels.firstName") + " " + t("isRequired")
    ),
    lastName: Yup.string().required(
      t("requeridLastName") + " " + t("isRequired")
    ),
    phone: showAlertPhoneEmail
      ? Yup.number()
          .required(t("modalAddProspect.phoneEmailRequerid"))
          .test(
            "phoneFormat",
            t("modalAddProspect.invalidPhoneFormat"),
            // If there is no value, the test passes; otherwise, it is evaluated if it is greater than or equal to 8 digits
            (value) => !value || value.toString().length >= 8
          )
      : Yup.number().test(
          "phoneFormat",
          t("modalAddProspect.invalidPhoneFormat"),
          // If there is no value, the test passes; otherwise, it is evaluated if it is greater than or equal to 8 digits
          (value) => !value || value.toString().length >= 8
        ),
    email: showAlertPhoneEmail
      ? Yup.string().required(t("modalAddProspect.phoneEmailRequerid"))
      : Yup.string().email(t("modalAddProspect.invalidEmailFormat")),
    addSource: addingAcquisitionMethod
      ? Yup.string().required(
          t("textAcquisitionSource") + " " + t("isRequired")
        )
      : Yup.string(),
    schoolLevel: Yup.string()
      .required(
        t("modalAcademicView.textAcademicLevel") + " " + t("isRequired")
      )
      .test(
        "NoDefaultValue",
        t("modalAcademicView.textAcademicLevel") + " " + t("isRequired"),
        (value) => {
          if (value) {
            return value != "00";
          }
        }
      ),
    schoolOrigin: Yup.string().required(
      t("crm:modalAddProspect.schoolOrigin") + " " + t("isRequired")
    ),
    program: higherLevel
      ? Yup.string()
          .required(t("modalAddProspect.academicOffer") + " " + t("isRequired"))
          .test(
            "NoDefaultValue",
            t("modalAddProspect.academicOffer") + " " + t("isRequired"),
            (value) => {
              if (value) {
                return value != "00";
              }
            }
          )
      : Yup.string(),
    grade: !higherLevel
      ? Yup.string().required(
          t("modalCreateStudent:labels.grade") + " " + t("isRequired")
        )
      : Yup.string(),
    acquisitionMethod: Yup.string()
      .required(t("acquisitionMedium") + " " + t("isRequired"))
      .test(
        "NoDefaultValue",
        t("acquisitionMedium") + " " + t("isRequired"),
        (value) => {
          if (value) {
            return value != "00" && value !== "add";
          }
        }
      ),
    homePhone: Yup.number()
      .test(
        "homephoneFormat",
        t("modalAddProspect.invalidHomephoneFormat"),
        // If there is no value, the test passes; otherwise, it is evaluated if it is greater than or equal to 8 digits
        (value) => !value || value.toString().length >= 8
      )
      .notRequired(),
  });
  const prefix = process.env.REACT_APP_PREFIX;

  /**
   * Get Adquisition Method
   */
  const getAdquisition = () => {
    const acquisitionMethodsData = props.acquisitionMethodsData;
    let data = acquisitionMethodsData;
    let methods = [];
    let optionAdd = {
      value: "add",
      label: t("textAddSource"),
    };
    methods.push(optionAdd);
    for (let item of data) {
      if (item.active == 1) {
        methods.push({
          value: item.id,
          label: item.name,
        });
      }
    }
    setMethodsAcquisition(methods);
  };

  /**
   * Get higher levels
   */
  const getCurrenCycle = () => {
    const cyclesLocal = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    let activeCycles = [];
    let schoolCollege = [];
    let currentCycle = false;
    let data = cyclesLocal;

    currentCycle = data.find((cycle) => cycle.current_cycle == 1);
    currentCycle = currentCycle ? currentCycle : data[0];
    //Get active cycles
    activeCycles = data.filter((cycle) => cycle.current === "1");
    //Extract all colleges _and_ schools from active cycles
    for (const activeCycle of activeCycles) {
      const cycleName = activeCycle.school_cycle;
      const cycleColleges = activeCycle.colleges_and_schools;
      //Extract all qualified schools
      for (const college of cycleColleges) {
        const collegeLevels = college.school_levels;
        for (const level of collegeLevels) {
          const programs = level.programs;
          for (const program of programs) {
            const programOption = {};
            if (program.enabled === "1") {
              programOption.id = program.id;
              programOption.label = program.name + " - " + cycleName;
              programOption.cycleId = activeCycle.id;
              programOption.value = activeCycle.id + program.id;
              schoolCollege.push(programOption);
            }
          }
        }
      }
    }
    setschoolCycleId(currentCycle.id);
    setCollagesAndSchool(schoolCollege);
    getLevels(activeCycles, schoolCollege);
  };

  /**
   *  Get school levels
   * @param {array} activeCycles
   * @param {array} schoolCollege
   */
  const getLevels = (activeCycles, schoolCollege) => {
    getSchoolLevels().then((result) => {
      let levels = [];
      let data = result.data.data;
      //Get all levels from active cycles
      for (const activeCycle of activeCycles) {
        if (data && data.length > 0) {
          for (let level of data) {
            const cycleName = activeCycle.school_cycle;
            const schoolLevels = activeCycle.school_levels;
            for (const schoolLevel of schoolLevels) {
              if (level.id == schoolLevel.school_level_id) {
                let levelCopy = { ...level };
                levelCopy.schoolLevelId =
                  schoolLevel.organization_school_level_id;
                levelCopy.name = schoolLevel.school_level_name;
                levelCopy.schoolLevelCycleId = schoolLevel.id;
                levelCopy.grades = schoolLevel.grades;
                levelCopy.value = schoolLevel.id;
                levelCopy.label =
                  schoolLevel.school_level_name + " - " + cycleName;
                levelCopy.cycleId = activeCycle.id;
                if (schoolLevel.grades.length) {
                  levels.push(levelCopy);
                }
              }
            }
          }
        }
      }
      //Add academic offer option if there are programs
      if (schoolCollege.length > 0) {
        const optionAcademicOfert = {
          label: t("modalAddProspect.textHigherEducation"),
          value: "PH",
          schoolLevelId: "PH",
        };
        levels.push(optionAcademicOfert);
      }
      setSchoolLevels(levels);
    });
  };
  /**
   * Capitalizes the string.
   * @param {String} string
   * @returns {String} capitalizedString
   */
  const capitalizeString = (string) => {
    const firstLetterUpperCase = string[0].toUpperCase();
    const restOfStringLowerCase = string.slice(1).toLowerCase();
    const mergedString = firstLetterUpperCase + restOfStringLowerCase;
    return mergedString;
  };

  /**
   * Add the acquisition method
   * @param {string} value
   * @returns {JSON object} JSON object
   */

  const createMethod = async (value) => {
    const capitalizeMethod = capitalizeString(value);
    return await CreateAcquisitionMethods(capitalizeMethod).then((result) => {
      if (result && result.description) {
        setError(result.description);
      } else {
        let data = result.data;
        getAdquisition();
        let obj = {
          label: data.name,
          value: data.id,
        };
        return obj;
      }
    });
  };

  /**
   * Initial loading
   */
  useEffect(() => {
    getAdquisition();
    getCurrenCycle();
  }, []);

  useEffect(() => {
    if (!showAcademicModalView) {
      getCurrenCycle();
    }
  }, [showAcademicModalView]);

  /**
   * Purpose: Redirect to prospect list, tasks and activities in CRM
   * @param {String} tabView
   */
  const goApp1 = (tabView) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/crm/${tabView}`);
  };

  /**
   * Create leaflet
   * @param {Object} values
   */
  const createLeaflet = async (values) => {
    setIsProcessing(true);
    setLoadCreateLeaflet(true);
    let data = {};
    data.first_name = values.firstName.toUpperCase();
    data.last_name = values.lastName.toUpperCase();
    data.middle_name = values.middleName && values.middleName.toUpperCase();
    values.homePhone ? (data.home_phone = values.homePhone) : null;
    values.email ? (data.email = values.email) : null;
    values.phone ? (data.phone = values.phone) : null;
    data.acquisition_method_id = [values.acquisitionMethod];
    data.school_origin = values.schoolOrigin;
    data.school_cycle_id = values.cycleId;
    values.schoolLevel === "PH"
      ? (data.program_id = values.program)
      : ((data.organization_school_level_id = values.schoolLevel),
        (data.grade_level_id = values.grade));
    values.address ? (data.address = values.address) : null;
    createleaflet(data)
      .then((result) => {
        if (result.code) {
          setError(result.description);
        } else {
          return result.data;
        }
      })
      .then((newLead) => {
        if (newLead && newLead.id) {
          goApp1("lead_profile/" + newLead.id);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadCreateLeaflet(false);
        setIsProcessing(false);
      });
  };

  /**
   * Get levels with grades
   * @param {Object} event
   */
  const changeSchoolLevel = (event) => {
    if (event.value !== "PH") {
      let grades = [];
      const position = schoolLevels.findIndex(
        (level) => level.schoolLevelCycleId == event.schoolLevelCycleId
      );
      grades = schoolLevels[position].grades;
      for (let item of grades) {
        item.value = item.id;
        item.label = item.grade_level;
      }
      setGrades(grades);
      sethigherLevel(false);
    } else {
      sethigherLevel(true);
    }
    setOferAcademic(event);
    setGradeSelected(null);
    setacademicOffert(null);
  };

  /**
   * Function for clearing modal values and closing the modal
   */
  const closeModal = () => {
    setOferAcademic(null),
      setGradeSelected(null),
      setacademicOffert(null),
      setadquisitionMethod(null);
    setInitialValues({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      schoolLevel: "",
      schoolOrigin: "",
      grade: "",
      acquisitionMethod: "",
      program: "",
      addSource: "",
    });
    props.setshowModalAddProspect(false);
    setError(false);
  };

  //View
  return (
    <div>
      <Modal
        show={props.showModalAddProspect}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title text-center">
            {t("crm:modalAddProspect.addProspect")}
          </Modal.Title>
        </Modal.Header>
        <ModalBody className="modal-body mbottom10">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchema}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                !loadCreateLeaflet ? createLeaflet(values) : "";
              }}
            >
              {(values) => (
                <Form>
                  <p> {t("modalAddProspect.cadidateInformation")}</p>
                  <Row>
                    <Col lg={6}>
                      <FieldInput
                        name="firstName"
                        type="text"
                        label={t("modalCreateStudent:labels.firstName")}
                        placeholder="PEDRO"
                        capitalize={true}
                        required={true}
                      />
                    </Col>
                    <Col lg={6}>
                      <FieldInput
                        name="middleName"
                        type="text"
                        label={t("middleName")}
                        placeholder="JUAN"
                        capitalize={true}
                      />
                    </Col>
                  </Row>
                  {/*last name and phone number*/}
                  <Row className="mbottom10">
                    <Col lg={6}>
                      <FieldInput
                        name="lastName"
                        type="text"
                        label={t("modalAddProspect.lastName")}
                        placeholder="PEREZ"
                        capitalize={true}
                        required={true}
                      />
                    </Col>
                    <Col lg={6}>
                      <FieldInput
                        name="phone"
                        type="tel"
                        label={t("cellPhone")}
                        placeholder={t("cellPhonePlaceholder")}
                        maxlength="10"
                        onInput={(e) => {
                          const regex = /^\d*$/; // Regular expression for numbers only
                          const inputValue = e.target.value;
                          if (!regex.test(inputValue)) {
                            // If it does not match the regular expression, remove the last character
                            e.target.value = inputValue.slice(0, -1);
                          }
                        }}
                      />
                      {/**Validated optional values*/}
                      {values &&
                      values.values &&
                      values.values.phone === "" &&
                      values.values.email === ""
                        ? setShowAlertPhoneEmail(true)
                        : setShowAlertPhoneEmail(false)}
                      {/**Deafault label value is requiered*/}
                      {values &&
                      values.errors &&
                      !values.errors.email &&
                      !values.errors.phone &&
                      values.values.phone === "" &&
                      values.values.email === "" ? (
                        <div className="helpBlockContainerV2">
                          <HelpBlock
                            label={t("modalAddProspect.phoneEmailRequerid")}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  {/*Home Phone & Email*/}
                  <Row className="mbottom10">
                    {/*Home Phone */}
                    <Col lg={6}>
                      <FieldInput
                        name="homePhone"
                        type="tel"
                        maxlength="10"
                        label={t("modalCreateStudent:labels.homePhone")}
                        placeholder={t("cellPhonePlaceholder")}
                        onInput={(e) => {
                          const regex = /^\d*$/; // Regular expression for numbers only
                          const inputValue = e.target.value;
                          if (!regex.test(inputValue)) {
                            // If it does not match the regular expression, remove the last character
                            e.target.value = inputValue.slice(0, -1);
                          }
                        }}
                      />
                    </Col>
                    {/*Email */}
                    <Col lg={6}>
                      <FieldInput
                        name="email"
                        type="text"
                        label={t("modalCreateStudent:labels.email")}
                        placeholder="name@example.com"
                      />
                      {/**Validated optional values*/}
                      {values &&
                      values.values &&
                      values.values.phone === "" &&
                      values.values.email === ""
                        ? setShowAlertPhoneEmail(true)
                        : setShowAlertPhoneEmail(false)}
                      {/**Deafault label value is requiered*/}
                      {values &&
                      values.errors &&
                      !values.errors.email &&
                      !values.errors.phone &&
                      values.values.phone === "" &&
                      values.values.email === "" ? (
                        <div className="helpBlockContainerV2">
                          <HelpBlock
                            label={t("modalAddProspect.phoneEmailRequerid")}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  {/*school of origin and school level*/}
                  <Row>
                    <Col lg={6}>
                      <FormSelect
                        label={t("modalCreateStudent:labels.schoolLevel")}
                        name="schoolLevel"
                        options={schoolLevels}
                        placeholder={t("selectSchoolLeve")}
                        onChange={(event) => {
                          changeSchoolLevel(event);
                          values.setFieldValue(
                            "schoolLevel",
                            event.schoolLevelId
                          );
                          values.setFieldValue("cycleId", event.cycleId);
                        }}
                        value={oferAcademic}
                        required={true}
                      />
                    </Col>
                    <Col lg={6}>
                      <FieldInput
                        name="schoolOrigin"
                        type="text"
                        label={t("crm:modalAddProspect.schoolOrigin")}
                        placeholder={t("modalAddProspect.schoolOrigin")}
                        required={true}
                      />
                    </Col>
                  </Row>
                  {/*School cycle & Grade*/}
                  <Row>
                    {values.values.schoolLevel === "PH" ? null : (
                      <Col lg={6}>
                        <FormSelect
                          label={t("modalCreateStudent:labels.grade")}
                          name="grade"
                          options={grades}
                          onChange={(event) => {
                            setGradeSelected(event);
                            values.setFieldValue("grade", event.value);
                          }}
                          value={gradeSelected}
                          placeholder={t("selectGrade")}
                          required={true}
                        />
                      </Col>
                    )}
                  </Row>

                  {/*Here is the single view of academic offerings */}
                  {values.values.schoolLevel === "PH" ? (
                    <Row>
                      <Col lg={12}>
                        <Col lg={12}>
                          <FormSelect
                            label={t("modalAddProspect.academicOffer")}
                            name="program"
                            options={collagesAndSchool}
                            onChange={(event) => {
                              setacademicOffert(event);
                              values.setFieldValue("program", event.id);
                              values.setFieldValue("cycleId", event.cycleId);
                            }}
                            value={academicOffert}
                            placeholder={t("selectOffer")}
                            required={true}
                          />
                        </Col>
                        <Button
                          style={{ marginRight: "10px", marginTop: "5px" }}
                          onClick={() => {
                            localStorage.setItem(
                              "prospectInfo",
                              JSON.stringify(values.values)
                            );
                            setshowAcademicModalView(true);
                            props.setshowModalAddProspect(false);
                          }}
                        >
                          {t("modalAddProspect.addProgram")}
                        </Button>
                      </Col>
                    </Row>
                  ) : null}

                  {/*Address */}
                  <Row>
                    <Col lg={12}>
                      <FieldInput
                        name="address"
                        type="text"
                        label={t("modalAddProspect.address")}
                        placeholder={t("textColony")}
                      />
                    </Col>
                  </Row>

                  {/*Means of acquisition  */}
                  <Row>
                    <p>{t("modalAddProspect.textadAcquisition")}</p>
                    <Col lg={12}>
                      <FormSelect
                        label={t("modalAddProspect.textThrough")}
                        name="acquisitionMethod"
                        options={methodsAcquisition}
                        value={adquisitionMethod}
                        onChange={(event) => {
                          setadquisitionMethod(event);
                          setAddingAcquisitionMethod(event.value === "add");
                          values.setFieldValue(
                            "acquisitionMethod",
                            event.value
                          );
                        }}
                        placeholder={t("textSelectSource")}
                        required={true}
                      />
                    </Col>
                  </Row>

                  {values.values.acquisitionMethod === "add" ? (
                    <Row>
                      <Col lg={12}>
                        <Col lg={12}>
                          <FieldInput
                            name="addSource"
                            type="text"
                            label={t("modalAddProspect.textAdquisitionSouce")}
                            placeholder={t("textSource")}
                            required={true}
                          />
                        </Col>
                        <Button
                          style={{ marginRight: "10px", marginTop: "5px" }}
                          variant="outline-secondary"
                          onClick={async () => {
                            let collageObj = await createMethod(
                              values.values.addSource
                            );
                            if (collageObj) {
                              setadquisitionMethod(collageObj);
                              setAddingAcquisitionMethod(false);
                              values.setFieldValue(
                                "acquisitionMethod",
                                collageObj.value
                              );
                              values.setFieldValue("addSource", "");
                            }
                          }}
                        >
                          {t("modalAddProspect.textBtnAddSource")}
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                  {/**Show errors when creating a prospect*/}
                  {error && (
                    <div className="mtop20">
                      <Alert variant="warning">{error}</Alert>
                    </div>
                  )}
                  <ContentButton className="content-button">
                    <Button variant="outline-secondary" onClick={closeModal}>
                      {t("global:buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      disabled={isProcessing || showAcademicModalView}
                    >
                      {isProcessing ? (
                        <TextLoading
                          text={t("btnAddProspect")}
                          variant="white"
                        />
                      ) : (
                        <>{t("modalAddProspect.addCandidate")}</>
                      )}
                    </Button>
                  </ContentButton>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <AcademicModalView
        showAcademicModalView={showAcademicModalView}
        setShowAcademicModalView={setshowAcademicModalView}
        setShowModalAddProspect={props.setshowModalAddProspect}
        setInitialValues={setInitialValues}
        schoolCycleId={schoolCycleId}
      />
    </div>
  );
}

ModalAddProspect.propTypes = {
  showModalAddProspect: PropTypes.bool,
  setshowModalAddProspect: PropTypes.func,
  acquisitionMethodsData: PropTypes.array,
};
