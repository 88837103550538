import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CopyClassCriteria } from "../../../api/class";
import TextLoading from "../../global/TextLoading";

const CopyCriterian = (props) => {
  const [t] = useTranslation(["global", "captureGrades"]);
  const [classSelected, setClassSelected] = useState("");
  const [saving, setSaving] = useState(false);

  const saveCopyCriterian = () => {
    setSaving(true);
    const payload = {
      source_class_id: classSelected,
      target_class_id: props.classSelected.id,
    };

    CopyClassCriteria(payload)
      .then((response) => {
        if (response.data) {
          // Add id of main criteria into second criterio to find
          for (const criteria of response.data) {
            if (criteria.second_criteria?.length) {
              for (const secondCriteria of criteria.second_criteria) {
                secondCriteria.first_criteria_id =
                  criteria.grading_criterion_class_id;
              }
            }
          }
          // Update criteria list
          props.setCriterionList(response.data);
          // Update criteria selected
          const criteriaSelected = response.data[0]?.second_criteria?.length
            ? response.data[0].second_criteria[0]
            : response.data[0];
          props.updateCriteriaSelected(criteriaSelected);
        }
      })
      .catch(() => {})
      .finally(() => {
        setSaving(false);
        props.onHide(false);
      });
  };

  return (
    <Modal show={props.showCopyCriterian}>
      <Modal.Header>
        <Modal.Title className="modal-title">
          {t("captureGrades:modalCopyCriterian.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}> {t("captureGrades:modalCopyCriterian.message")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <FormGroup>
              <FormLabel className="mb-0">
                {t("captureGrades:modalCopyCriterian.inputLabel")}
              </FormLabel>
              <Form.Select
                id="groupId"
                size="sm"
                placeholder={t(
                  "captureGrades:modalCopyCriterian.inpuPlaceholder"
                )}
                value={classSelected}
                onChange={(e) => {
                  setClassSelected(e.target.value);
                }}
              >
                <option hidden>
                  {t("captureGrades:modalCopyCriterian.inpuPlaceholder")}
                </option>
                {props.criteriaClassesToCopy.map((item, index) => (
                  <option key={index} value={item.class_id}>
                    {item.class_name}
                  </option>
                ))}
              </Form.Select>
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button
          variant="outline-danger"
          onClick={() => {
            props.setShowChooseCopyOrNew(true);
            props.onHide(false);
          }}
          className="float-start"
          disabled={saving}
        >
          <i className="bi bi-chevron-left" />
          {t("global:buttons.backTo")}
        </Button>
        <div className="float-end">
          <Button
            variant="outline-secondary"
            onClick={() => props.onHide(false)}
            disabled={saving}
          >
            {t("global:buttons.cancel")}
          </Button>
          <Button
            onClick={saveCopyCriterian}
            disabled={saving || !classSelected}
          >
            {saving ? (
              <TextLoading
                text={t("global:buttons.textAdding")}
                variant="light"
              />
            ) : (
              t("global:buttons.add")
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
CopyCriterian.propTypes = {
  showCopyCriterian: PropTypes.bool,
  onHide: PropTypes.func,
  setShowChooseCopyOrNew: PropTypes.func,
  criteriaClassesToCopy: PropTypes.array,
  classSelected: PropTypes.object,
  setCriterionList: PropTypes.func,
  updateCriteriaSelected: PropTypes.func,
};
export default CopyCriterian;
